import React, { useState, useEffect, useRef, useContext } from "react";
import Card from "../common/Card/Card";
import Select from "../common/Select";
import Button from "../common/Button";
import Loader from "../common/Loader";
import ContentEmpty from "../common/ContentEmpty";
import ModalShowCase from "../common/Modal/ModalShowCase";
import { HeaderContext } from "../HeaderContext";
import { fetchDataEES, filterEESS, getAreas, getCities, getStates } from "../../assets/js/dataHelpers";
import { motion } from "framer-motion";
const LIMIT = 6;
let page = 0;

function History() {
  const [historys, setHistorys] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [cities, setCities] = useState(false);
  const [states, setStates] = useState(null);
  const [areas, setAreas] = useState(null);
  const [selectedValue, setSelectedValue] = useState('default');
  const [value, setValue] = useState('');
  const [select, setSelect] = useState('');
  const [filterActived, setFilterActived] = useState(false);
  const sliderRef = useRef();
  const globalHeader = useContext(HeaderContext);
  const body = document.querySelector("html");

  globalHeader.setHeaderState(true);

  function openModal(e) {
    e.preventDefault();

    //Get isMobile
    const windowWidth = window.innerWidth;
    const slideNumber = e.currentTarget.getAttribute("id");

    if (windowWidth < 1024) return;

    sliderRef.current.slickGoTo(slideNumber);
    setIsOpen("is-open");
    body.style.overflow = "hidden";
  }

  function closeModal(e) {
    e.preventDefault();
    setIsOpen("");
    body.style.overflow = "auto";
  }

   // Apply select filters
   function applyFilter(e){
     const currentElement = e.currentTarget.getAttribute('name');
    const valueOption = e.currentTarget.value;

    //Setting option default of select
    setSelectedValue(value);
    setFilterActived(true);
    setSelect(currentElement)
    setValue(valueOption)

    if(currentElement === 'estado') {
      (async () => {
        const dataCities = await getCities('eess', valueOption);
        setCities(dataCities);
      })();
    };
    
    (async () => {
      const dataFiltered = await filterEESS(false, page, LIMIT, currentElement, valueOption);
      dataFiltered.registros.length ? setHistorys(dataFiltered.registros) : setHistorys(false);
    })();
  }

  // Getting More Action on the Click!
  function getMoreActions() {
    (async () => {
      page++;
      const dataEES = filterActived ? await filterEESS(true, page, LIMIT, select, value) : await fetchDataEES(true, LIMIT, page);
      const updateEES = historys.concat(dataEES.registros);

      dataEES.registros.length <= LIMIT ? setDisabled(true) : setDisabled(false);

      setHistorys(updateEES);
    })();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      page = 0;
      setIsLoading(true);
      const dataEES = await fetchDataEES(false, LIMIT, page);
      const dataAreas = await getAreas();
      const dataStates = await getStates('eess');

      // Setting data
      !dataEES.registros.length ? setNoResults(true) : setNoResults(false);
      dataEES.registros.length ? setHistorys(dataEES.registros) : setHistorys(false);
      setAreas(dataAreas.registros);
      setStates(dataStates);
      setIsLoading(false);
    })();
  }, []);

  return (
    <motion.section
      id="history"
      className={`history`}
      initial={{  opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="history-content">
        <h1 className="history-content--title">Histórico da Rede</h1>
        <p className="history-content--text container">
          Alguns empreendimentos têm seu ciclo de vida interrompido, porém,
          deixaram sua contribuição no fortalecimento e construção da rede, por
          isso continuam fazendo parte da nossa história. Conheça os
          Empreendimento Econômico Solidário - EES que passaram pela rede e que
          agora não estão mais em atividade.
        </p>
      </div>
      <form className={`history-form container ${noResults ? "d-none" : ""}`}>
        <div className="history-form-select">
          <Select name={"estado"} label={"Estado"} data={states} handleChange={applyFilter} />
        </div>
        <div className="history-form-select">
          <Select name={"cidade"} label={"Cidade"} data={cities} defaultValue={selectedValue} handleChange={applyFilter} />
        </div>
        <div className="history-form-select">
          <Select name={"area"} label={"Área de atuação"} data={areas} handleChange={applyFilter} />
        </div>
      </form>

      {isLoading && <Loader />}

      {!isLoading && !historys && (
        <ContentEmpty
          title={"Sem resultado no histórico :("}
          content={"Em breve, estaremmos atualizando as nossas novidades."}
        />
      )}

      {!isLoading && historys && (
        <div className={`history-cards ${disabled ? "no-results" : ""}`}>
          {historys.map((history, index) => {
            return (
              <Card
                {...history}
                index={index}
                key={history.nome}
                type={"showcase"}
                openModal={openModal}
              />
            );
          })}
        </div>
      )}

      {!isLoading && historys && (
        <Button
          buttonClass={`btn btn-secondary--dark history-btn ${
            disabled || noResults ? "d-none" : ""
          }`}
          content={"Ver mais"}
          handleClick={getMoreActions}
        />
      )}

      <ModalShowCase
        isOpen={isOpen}
        sliderRef={sliderRef}
        closeModal={closeModal}
        showcases={historys}
        imageClass={"grayscale"}
      />
    </motion.section>
  );
}

export default History;

import React, { useState, useEffect, useRef, useContext } from "react";
import Card from "../common/Card/Card";
import Select from "../common/Select";
import Button from "../common/Button";
import Loader from "../common/Loader";
import ContentEmpty from "../common/ContentEmpty";
import ModalShowCase from "../common/Modal/ModalShowCase";
import { HeaderContext } from "../HeaderContext";
import { fetchDataEES, filterEESS, getAreas, getCities, getStates } from "../../assets/js/dataHelpers";
import { motion } from "framer-motion";
const LIMIT = 6;
let page = 0;

function ShowCaseHistory() {
  const [showcases, setShowCases] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [cities, setCities] = useState(false);
  const [states, setStates] = useState(null);
  const [areas, setAreas] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [filterActived, setFilterActived] = useState(false);
  const [selectedValue, setSelectedValue] = useState('default');
  const [value, setValue] = useState('');
  const [select, setSelect] = useState('');
  const sliderRef = useRef();
  const globalHeader = useContext(HeaderContext);
  const body = document.querySelector("html");
  
  globalHeader.setHeaderState(true);

  function openModal(e) {
    e.preventDefault();

    //Get isMobile
    const windowWidth = window.innerWidth;
    const slideNumber = e.currentTarget.getAttribute("id");

    if (windowWidth < 1024) return;

    sliderRef.current.slickGoTo(slideNumber);
    setIsOpen("is-open");
    body.style.overflow = "hidden";
  }

  function closeModal(e) {
    e.preventDefault();
    setIsOpen("");
    body.style.overflow = "auto";
  }

  // Apply select filters
  function applyFilter(e){
    let currentElement = e.currentTarget.getAttribute('name');
    let valueOption = e.currentTarget.value;

    //Setting option default of select
    setSelectedValue(value);
    setFilterActived(true);
    setSelect(currentElement);
    setValue(valueOption);
    
    if(currentElement === 'estado') {
      (async () => {
        const dataCities = await getCities('eess', valueOption);
        setCities(dataCities);
      })();
    };
    
    if(currentElement === 'area') valueOption = Number(valueOption);
    
    // Setting values to make chained filters
    ;

    (async () => {
      const dataFiltered = await filterEESS(true, page, LIMIT, currentElement, valueOption);
      dataFiltered.registros.length ? setShowCases(dataFiltered.registros) : setShowCases(false);
    })();
  }

  // Getting More Action on the Click!
  function getMoreActions() {
    (async () => {
      page++;
      const dataEES = filterActived ? await filterEESS(true, page, LIMIT, select, value) : await fetchDataEES(true, LIMIT, page);
      const updateEES = showcases.concat(dataEES.registros);

      dataEES.registros.length <= LIMIT ? setDisabled(true) : setDisabled(false);

      setShowCases(updateEES);
    })();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      page = 0;
      setIsLoading(true);
      const dataEES = await fetchDataEES(true, LIMIT, page);
      const dataAreas = await getAreas();
      const dataStates = await getStates('eess');

      // Setting data
      !dataEES.registros.length ? setNoResults(true) : setNoResults(false);
      dataEES.registros.length ? setShowCases(dataEES.registros) : setShowCases(false);
      setAreas(dataAreas.registros);
      setStates(dataStates);
      setIsLoading(false);
    })();
  }, []);

  return (
    <motion.section
      id="showcase-history"
      className={`showcase-history`}
      initial={{  opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="showcase-history-content">
        <h1 className="showcase-history-content--title">Vitrine</h1>
        <p className="showcase-history-content--text container">
          Esta é a vitrine da rede, aqui você pode conhecer e localizar o
          Empreendimento Econômico Solidário - EES mais próximo de você e seus
          produtos e/ou serviços de seu interesse. Conheça abaixo:
        </p>
      </div>
      <form
        className={`showcase-history-form container ${
          noResults ? "d-none" : ""
        }`}
      >
        <div className="showcase-history-form-select">
          <Select name={"estado"} label={"Estado"} data={states} handleChange={applyFilter}/>
        </div>
        <div className="showcase-history-form-select">
          <Select name={"cidade"} label={"Cidade"} data={cities} defaultValue={selectedValue} handleChange={applyFilter}/>
        </div>
        <div className="showcase-history-form-select">
          <Select name={"area"} label={"Área de atuação"} data={areas} handleChange={applyFilter}/>
        </div>
      </form>

      {isLoading && <Loader />}

      {!isLoading && !showcases && (
        <ContentEmpty
          title={"Sem resultado no nosso histórico :("}
          content={"Em breve, estaremmos atualizando as nossas novidades."}
        />
      )}

      {!isLoading && showcases && (
        <div
          className={`showcase-history-cards ${disabled ? "no-results" : ""}`}
        >
          {showcases.map((showcase, index) => {
            return (
              <Card
                {...showcase}
                index={index}
                key={showcase.nome}
                type={"showcase"}
                openModal={openModal}
              />
            );
          })}
        </div>
      )}

      {!isLoading && (
        <Button
          buttonClass={`btn btn-secondary--dark showcase-history-btn ${
            disabled || noResults ? "d-none" : ""
          }`}
          content={"Ver mais"}
          handleClick={getMoreActions}
        />
      )}

      <ModalShowCase
        isOpen={isOpen}
        sliderRef={sliderRef}
        closeModal={closeModal}
        showcases={showcases}
      />
    </motion.section>
  );
}

export default ShowCaseHistory;

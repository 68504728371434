import React, { createContext } from "react";
import { useState } from "react";

export const HeaderContext = createContext();

export const GlobalHeaderContext = ({ children }) => {
  const [headerState, setHeaderState] = useState(false);
  return (
    <HeaderContext.Provider value={{ headerState, setHeaderState }}>
      {children}
    </HeaderContext.Provider>
  );
};

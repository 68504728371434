let previousFilterAction;
let nextFilterAction;
function getFilterActions(filter, select, value) {
    // Condition when used the first time
    if (!previousFilterAction) {
        previousFilterAction = `${select}=${value}`;
        return filter;
    }

    // Condition for change the used filter
    if (previousFilterAction.includes(select)) {
        // Regex for find used filter
        const regex = new RegExp(`\\b${select}=[^&]+\\b`, 'gi');

        // Replace value for the same filter type
        const newFilter = previousFilterAction.replace(regex, filter);
        previousFilterAction = newFilter;
        return newFilter;
    }

    // Return when used always different filters
    nextFilterAction = `${previousFilterAction}&${select}=${value}`;
    previousFilterAction = nextFilterAction;
    return nextFilterAction
}

let previousFilterESS;
let nextFilterESS;
function getFilterEES(filter, select, value) {
    // Condition when used the first time
    if (!previousFilterESS) {
        previousFilterESS = `${select}=${value}`;
        return filter;
    }

    // Condition for change the used filter
    if (previousFilterESS.includes(select)) {
        // Regex for find used filter
        const regex = new RegExp(`\\b${select}=[^&]+\\b`, 'gi');

        // Replace value for the same filter type
        const newFilter = previousFilterESS.replace(regex, filter);
        previousFilterESS = newFilter;
        return newFilter;
    }

    // Return when used always different filters
    nextFilterESS = `${previousFilterESS}&${select}=${value}`;
    previousFilterESS = nextFilterESS;
    return nextFilterESS
}

export async function fetchData(limit, page) {

    const url = `${process.env.REACT_APP_URL_BACKEND}/acoes?limit=${limit}&page=${page}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function fetchDataEES(status, limit, page) {

    const url = `${process.env.REACT_APP_URL_BACKEND}/eess?status=${status}&limit=${limit}&page=${page}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export function getListName(partners, type) {
    const list = partners.map((partner) =>
        partner[type] ? partner.nome : null
    );
    return list;
}

export function getMonthName(month) {
    const monthsName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    return {
        id: month,
        nome: monthsName[month - 1]
    }
}

export async function getYears(type) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/anos`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getMounths(type) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/meses`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getStates(type) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/estados`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getCities(type, state) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/${type}/cidades?estado=${state}`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function getAreas() {
    const url = `${process.env.REACT_APP_URL_BACKEND}/areas`;

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}

export async function filterActions(page, limit, select, value) {
    const filter = `${select}=${value}`;
    const nextFilter = getFilterActions(filter, select, value);

    const url = `${process.env.REACT_APP_URL_BACKEND}/acoes?page=${page}&limit=${limit}&${nextFilter}`;
    console.log(url)
    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}


export async function filterEESS(status, page, limit, select, value) {
    const filter = `${select}=${value}`;
    const nextFilter = getFilterEES(filter, select, value);

    const url = `${process.env.REACT_APP_URL_BACKEND}/eess?status=${status}&page=${page}&limit=${limit}&${nextFilter}`;
    console.log(url)
    try {
        const response = await fetch(url);
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.log(error);
    }
}


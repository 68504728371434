import React, { useState } from "react";
import { Link } from "react-router-dom";
import LinkMenu from "../common/LinkMenu";
import Form from "./Form";
import ModalPrivacy from "../common/Modal/ModalPrivacy";
import ModalPartners from "../common/Modal/ModalPartners";

function ContactUs() {
  const [isOpenPrivacy, setIsOpenPrivacy] = useState('');
  const [isOpenSupport, setIsOpenSupport] = useState('');
  const body = document.querySelector('html');

  function openModal(e) {
    e.preventDefault();

    const type = e.target.getAttribute('data-id');
    type === 'privacy' ? setIsOpenPrivacy('is-open') : setIsOpenSupport('is-open');
    body.style.overflow = 'hidden';
  }

  function closeModal(e){
    e.preventDefault();

    setIsOpenPrivacy('');
    setIsOpenSupport('');
    body.style.overflow = 'auto';
  }
  
  return (
    <section id="contact-us" className="contact-us">
      <div className="contact-us-content container">
        <div className="contact-us-content-info">
          <p>saudeecosol@gmail.com</p>
          <Link to={"https://www.instagram.com/saudeecosol/?hl=pt-br"} className={"link"} rel='noopener noreferrer' target="_blank">Instagram</Link>
          <Link to={"https://www.facebook.com/redesaudemental.ecosol"} className={"link"} rel='noopener noreferrer' target="_blank">Facebook</Link>
          <Link to={"https://www.youtube.com/@saudeecosol"} className={"link"} rel='noopener noreferrer' target="_blank">Youtube</Link>
        </div>

        <div className="contact-us-content-links">
          <ul className="menu-footer">
            <li>
              <Link className="link" to={"/#about-us"} state={'about-us'}>Quem somos</Link>
            </li>
            <li>
              <Link className="link" to={"/#we-do"} state={'we-do'}>O que fazemos</Link>
            </li>
            <li>
              <Link className="link" to={"/#actions"} state={'actions'}>Ações</Link>
            </li>
            <li>
              <Link className="link" to={"/#partners"} state={'partners'}>Financiadores</Link>
            </li>
            <li>
              <Link className="link" to={"/#calendar"} state={'calendar'}>Agenda</Link>
            </li>
          </ul>
          <ul className="menu-footer">
            <li onClick={openModal}>
              <LinkMenu
                dataID={"privacy"}
                content={"Política de Privacidade"}
              />
            </li>
            <li onClick={openModal}>
              <LinkMenu dataID={"support"} content={"Apoie o Movimento"} />
            </li>
            <li>
            <Link className="link" to={"/#articles"} state={'articles'}>Artigos</Link>
            </li>
            <li>
            <Link className={"link"} to={"/history"} state={"showcase"}>Histórico da Rede</Link>
            </li>
            <li>
            <Link className="link" to={"/#contact-us"} state={'contacts-us'}>Contato</Link>
            </li>
          </ul>
        </div>

        <div className="contact-us-content-form">
          <Form />
        </div>
      </div>
      <ModalPrivacy isOpen={isOpenPrivacy} closeModal={closeModal}/>
      <ModalPartners isOpen={isOpenSupport} closeModal={closeModal}/>
    </section>
  );
}

export default ContactUs;

import React from "react";

function Button({
  buttonClass,
  dataId,
  content,
  ariaLabel,
  ariaHaspopup,
  ariaExpanded,
  ariaControls,
  handleClick,
}) {
  return (
    <button
      className={buttonClass || ""}
      data-id={dataId || ""}
      aria-label={ariaLabel || ""}
      aria-haspopup={ariaHaspopup || ""}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls || ""}
      onClick={handleClick || null}
    >
      {content}
    </button>
  );
}

export default Button;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { GlobalHeaderContext } from './components/HeaderContext';
import { BrowserRouter } from "react-router-dom";
import {loadGoogleClient} from "./services/googleAgendaSerivce";

//Load API Calendar
loadGoogleClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalHeaderContext>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GlobalHeaderContext>
  </React.StrictMode>
);

import React from "react";

function ContentEmpty({title, content}) {
  return (
    <div className="content-empty">
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  );
}

export default ContentEmpty;

import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { getImageAltFromImagePath } from "../../assets/js/imageHelper";
import Image from "../common/Image";
import ModalPartners from "../common/Modal/ModalPartners";

function Partners() {
  const [partners, setPartners] = useState(null);
  const [isOpen, setIsOpen] = useState('');
  const body = document.querySelector('html');

  function openModal(e) {
    e.preventDefault();
    setIsOpen('is-open');
    body.style.overflow = 'hidden';
  }

  function closeModal(e){
    e.preventDefault();
    setIsOpen('');
    body.style.overflow = 'auto';
  }

  useEffect(() => {
    (async () => {
      const url = `${process.env.REACT_APP_URL_BACKEND}/parceiros`;

      try {
        const response = await fetch(url);
        const responseData = await response.json();
        setPartners(responseData.registros);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const partnersList =
    partners && partners.filter((item) => item.is_site_parceiro && item.imagem);
  const financiersList =
    partners && partners.filter((item) => item.is_site_financiador && item.imagem);

  return (
    <section id="partners" className="partners">
      <div className="partners-content">
        <div className="financiers-tab">
          <div className="financiers-tab-images">
          {financiersList &&
              financiersList.map((financier) => {
                const imgData = financier.imagem;
                const imgAlt = getImageAltFromImagePath(imgData.nome);

                return <Image src={imgData.url} alt={imgAlt} key={imgData.key} />;
              })}
          </div>
          <Button
            buttonClass="btn btn-secondary--dark actions-content-btn"
            content="Como Apoiar"
            handleClick={openModal}
          />
        </div>

        <div className="partners-tab">
          <div className="partners-tab-images">
            {partnersList &&
              partnersList.map((partner) => {
                const imgData = partner.imagem;
                const imgAlt = getImageAltFromImagePath(imgData.nome);

                return <Image src={imgData.url} alt={imgAlt} key={imgData.key} />;
              })}
          </div>
          <h2 className="partners-tab-title">
            Parcei
            <br />
            ros
          </h2>
        </div>
      </div>
      <ModalPartners isOpen={isOpen} closeModal={closeModal} />
    </section>
  );
}

export default Partners;

import React from "react";
import Slider from "react-slick";
import { getImageAndAlt } from "../../../assets/js/imageHelper";
import { getListName } from "../../../assets/js/dataHelpers";
import { getDate } from "../../../assets/js/dateHelper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ModalCard({ isOpen, actions, sliderRef, closeModal }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className={`modal-card ${isOpen}`}
      id="modal-card"
      tabIndex="-1"
      role="dialog"
      aria-hidden={!isOpen ? true : false}
    >
      <div className="modal-card-container">
        <button
          className="modal-card-btn"
          aria-label="Fechar Modal"
          onClick={closeModal}
        ></button>
        <Slider {...settings} ref={sliderRef}>
          {actions &&
            actions.map((action) => {
              const dataImage = getImageAndAlt(action.imagens);
              const date = getDate(action.data_inicio, action.data_fim);
              const partners =
                action.parceiros &&
                getListName(action.parceiros, "is_site_parceiro");
              const financiers =
                action.parceiros &&
                getListName(action.parceiros, "is_site_financiador");
              const managers =
                action.parceiros &&
                action.parceiros.filter((manager) =>
                  manager.tipos.filter(
                    (manager) => manager.nome === "Realizador"
                  )
                );
              return (
                <div key={action.nome}>
                  <div id={action.id} className="modal-card-slider">
                    <div className="modal-card-content">
                      <h3 className="modal-card-content--title">
                        {action.nome}
                      </h3>
                      <p className="modal-card-content--description">
                        {action.descricao}
                      </p>
                    </div>

                    <img
                      className="modal-card-image"
                      src={dataImage.src}
                      alt={dataImage.alt}
                    />

                    <div className="modal-card-tags--info">
                      <div className="card-tag">
                        <span className="card-tag--title">Cidade - UF</span>
                        <span className="card-tag--content">
                          {action.cidade} - {action.estado}
                        </span>
                      </div>
                      <div className="card-tag">
                        <span className="card-tag--title">Endereço</span>
                        <span className="card-tag--content">
                          <a href={`https://www.google.com/maps/search/${action.endereco}`} target="_blank" rel="noopener noreferrer">Como Chegar</a>
                        </span>
                      </div>
                      <div className="card-tag">
                        <span className="card-tag--title">Data</span>
                        <span className="card-tag--content">{date}</span>
                      </div>
                      <div className="card-tag">
                        <span className="card-tag--title">Impacto</span>
                        <span className="card-tag--content">
                          {action.impacto}
                        </span>
                      </div>
                    </div>
                    <div className="modal-card-tags--partners">
                      <div className="card-tag">
                        <span className="card-tag--title">Realizador</span>
                        {managers &&
                          managers.map((manager) => {
                            return (
                              <span className="card-tag--content">
                                {manager.nome}
                              </span>
                            );
                          })}
                      </div>
                      <div className="card-tag">
                        <span className="card-tag--title">Financiador</span>
                        {financiers &&
                          financiers.map((financier) => {
                            return (
                              <span className="card-tag--content">
                                {financier}
                              </span>
                            );
                          })}
                      </div>
                      <div className="card-tag">
                        <span className="card-tag--title">Parceiro</span>
                        {partners &&
                          partners.map((partner) => {
                            return (
                              <span className="card-tag--content">
                                {partner}
                              </span>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
}

export default ModalCard;

import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import ContentEmpty from "../common/ContentEmpty";
import Article from "./Article";
import Loader from "../common/Loader";

function handleClick(e) {
  e.preventDefault();

  const urlRedirect = "https://medium.com/@saudeecosol";
  window.open(urlRedirect, "_blank");
}

function Articles() {
  const [posts, setPosts] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    async function getPosts() {
      setIsloading(true);
      const mediumApi = process.env.REACT_APP_URL_MEDIUM;
      let postsList = [];

      const result = await axios.get(mediumApi);
      let mediumPosts = await result.data.items;

      setIsloading(false);
      
      // Array articles EMPTY!!
      if (!mediumPosts.length) return;

      mediumPosts.map((item, index) =>{
        return index < 4 && postsList.push(item)
      });

      setPosts(postsList);
    }

    getPosts();
  }, []);

  return (
    <section id="articles" className="articles">
      <div className="articles-content container">
        {isLoading && <Loader/>}

        {!isLoading && !posts && (
          <ContentEmpty
            title={"Ainda estamos sem artigos publicados  :("}
            content={`Estamos preparando conteúdos sensacionais para você e, em breve,
          retornaremos com novas publicações`}
          />
        )}

        {!isLoading && posts && (
          <>
            <div className="articles-content-list">
              {posts?.map((article) => {
                return <Article {...article} key={article.title} />;
              })}
            </div>

            <Button
              buttonClass={"btn btn-secondary--dark"}
              content={"mais artigos"}
              handleClick={handleClick}
            />
          </>
        )}
      </div>
      <h2 className="articles-title">Artigos</h2>
    </section>
  );
}

export default Articles;

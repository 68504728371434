export function getImageAltFromImagePath (imgPath) {
    if (!imgPath) {
        return '';
    }
    const imgFileName = imgPath.split('/').pop();
    const imgExtension = imgFileName.split('.').pop();
    const imgDirtyName = imgFileName.replace('.' + imgExtension, '');
    return imgDirtyName.replace(/(\.|_|-)/g, ' ');
};

export function getImageAndAlt(image){
    if(!image?.length) return "";
   
    return { 
        src: image[0].url || "",
        alt: getImageAltFromImagePath(image[0].nome) || "Imagem Card"
    }
}
import React from "react";

function WeDo() {
  return (
    <section id="we-do" className="we-do">
      <div className="we-do-content container">
        <h2 className="we-do-content--first-title">O que faze<br/>mos</h2>
        <div className="we-do-content-text">
          <p className="text-extra-bold">Seguimos os princípios da economia solidária.</p>
          <p>
            Entendemos o trabalho e geração de renda como um direito de todo
            cidadão.
          </p>
          <p>
            Promovemos <span className="text-extra-bold">emancipação individual e coletiva </span> por meio do fomento à criação e
            desenvolvimento de espaços democráticos de articulação e tomada de
            decisão, <span className="text-extra-bold">respeitando</span>, escutando e dando espaço a <span className="text-extra-bold">todas as vozes</span>.
          </p>
        </div>
        <h2 className="we-do-content--last-title">O que faze<br/>mos</h2>
        <h2 className="we-do-content--title-mobile">O que fazemos</h2>
      </div>
    </section>
  );
}

export default WeDo;

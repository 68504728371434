import React, { useState, useContext } from 'react';
import Logo from './Logo';
import Navbar from './Navbar';
import { HeaderContext } from '../HeaderContext';
import { debounce } from '../../assets/js/scrollHelper';


function Header() {
  const [stickyHeader, setStickyHeader] = useState(false);
  const globalHeader = useContext(HeaderContext);

  const headerClass = globalHeader.headerState ? 'header-violet' : 'header';

  let prevScrollpos = window.pageYOffset;

  window.onscroll = debounce(function() {
    let currentScrollPos = window.pageYOffset;
    
    if(currentScrollPos === 0){
      setStickyHeader(false)
    } else {
      if (prevScrollpos > currentScrollPos) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
    
    prevScrollpos = currentScrollPos;
  }, 50);

  return (
    <header className={`${headerClass} ${stickyHeader ? 'header-sticky' : ""}`}>
      <div className='header-container container'>
        <Logo />
        <Navbar />
        <Navbar menuClass={"-mobile"} />        
      </div>        
    </header>
  )
}

export default Header
import React from 'react'
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <div className='logo'>
        <Link to={"/"} title={"Rede de Saúde Mental e Economia Solidária"}>
          <img src="./images/logo.png" alt="Logo Movimento de Redes" />
        </Link>
    </div>
  )
}

export default Logo
import React from "react";

import Button from "../common/Button";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function Banner() {
  //Navigation Button
  const navigate = useNavigate();
  function handleClick(e) {
    const dataID = e.currentTarget.getAttribute("data-id");
    switch (dataID) {
      case "action":
        navigate("/actions-search");
        break;

      case "showcase":
        navigate("/showcase-search");
        break;

      default:
        navigate("/");
        break;
    }
  }

  return (
    <section id="banner" className="banner">
      <div className="banner-container container">
        <div className="banner-content">
          <h2 className="banner-content-title">
            Ideias que <span className="text-secondary--dark">conectam,</span>
            <br />
            ações solidárias
            <br />
            <span className="text-secondary--dark">que transformam</span>
          </h2>
          <div className="banner-content-cta">
            <Button
              buttonClass={"btn btn-secondary--dark"}
              dataId={"action"}
              content={"ações"}
              handleClick={handleClick}
            />
            <Button
              buttonClass={"btn btn-secondary--dark"}
              dataId={"showcase"}
              content={"vitrine"}
              handleClick={handleClick}
            />
          </div>
        </div>
        <Link to={'/#about-us'} state={"about-us"}>
          <div
            className="icon-scroll"
            aria-label="Icone para rolagem de conteúdo na tela"
            aria-labelledby="about-us"
          >
            <span className="icon-vertical-arrow" ></span>
          </div>
        </Link>
      </div>
    </section>
  );
}

export default Banner;

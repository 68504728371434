import React from "react";

function LinkMenu({ dataID, content, handleClick }) {
  return (
    <a href="./" className="link" data-id={dataID} onClick={handleClick}>
      {content}
    </a>
  );
}

export default LinkMenu;

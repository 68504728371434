import React from 'react';

function ActionCardTag({title, content, tagClass}) {
  return (
    <div className={`card-tag ${tagClass || ""}`}>
        <h4>{title}</h4>
        <p>{content}</p>
    </div>
  )
}

export default ActionCardTag
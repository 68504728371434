function Select({name, label, data, defaultValue, handleChange}) {
  
  const dataList = data && data.map(item =>{ 
    if(item.nome){
      return {
        value: item.id,
        label: item.nome
      };
    } else {
      return {
        value: item,
        label: item
      }
    }
  });


  return (
    <select name={`${name}`} id={`select-${name}`} value={defaultValue} disabled={!data} onChange={handleChange}>
        <option value="default" hidden selected>{label}</option>
        {dataList && dataList.map(data =>{
          return <option value={data.value}>{data.label}</option>
        })}        
    </select>
  )
}

export default Select
import React from "react";
import CardTag from "./CardTag";
import { getImageAltFromImagePath } from "../../../assets/js/imageHelper";
import { getDate } from "../../../assets/js/dateHelper";

function Card(props) {
  const type = props.type;
  const srcImage = (props?.imagens.length && props?.imagens[0].url) || "";
  const altImage = (props?.imagens.length && getImageAltFromImagePath(props?.imagens[0].nome)) || "Imagem Card";
  const date = getDate(props.data_inicio, props.data_fim);
  const areas = props.areas && props.areas.map(area => area.nome).join(', ');

  return (
    <div className="slider-content">
      <div
        id={props.index}
        className={`card ${props.cardClass || ""}`}
        onClick={props.openModal}
      >
        <img className="card-image" src={srcImage} alt={altImage} />
        <div className="card-info">
          <h2 className="card-info-title">{props.nome}</h2>
          <div className="card-info-tags">
            {type === 'action' && <CardTag title={"Data"} content={date} />}
            {type === 'action' && <CardTag title={"Impacto"} content={props.impacto} />}
            {<CardTag title={"Estado"} content={props.estado} />}
            {<CardTag title={"Cidade"} content={props.cidade} />}
            {type === 'action' && <CardTag title={"Endereço"} content={props.endereco} tagClass={'full-width'}/>}
            {type === 'showcase' && <CardTag title={"Bairro"} content={props.bairro} />}
            {type === 'showcase' && <CardTag title={"Telefone"} content={props.telefone} />}
            {type === 'showcase' && <CardTag title={"Área de atuação"} content={areas} tagClass={'full-width'}/>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;

import React from "react";

function AboutUs() {
  return (
    <section id="about-us" className="about-us">
      <div className="about-us-container container">
        <h2 className="about-us-title">Quem somos nós</h2>
        <div className="about-us-content">
          <p>
            Somos um movimento social autônomo consolidado em <span className="text-bold">2009</span>, criado a
            partir do movimento da <span className="text-bold">Reforma Psiquiátrica Brasileira</span> que se
            compromete com o tratamento humano em liberdade.
          </p>
          <p>
            Somos compostos por serviços de saúde mental, trabalhadores-usuários
            desses serviços, profissionais da saúde e empreendimentos de
            economia solidária.
          </p>
          <p>
            Integramos empreendimentos, oficinas de trabalho e projetos de
            geração de renda.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;

import React from 'react';
import Main from '../../components/templates/Main';
import ActionsHistory from '../../components/templates/ActionsHistory';
import ShowCaseHistory from '../../components/templates/ShowCaseHistory';
import History from '../../components/templates/History';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';

function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.key}>
        <Route element={<Main animated />} path="/" />
        <Route element={<ActionsHistory />} path="/actions-search" />
        <Route element={<ShowCaseHistory />} path="/showcase-search" />
        <Route element={<History />} path="/history" />
      </Routes>
    </AnimatePresence>
  )
}

export default AppRoutes
import React, { useEffect, useState, useContext } from "react";
import AboutUs from "../AboutUs/AboutUs";
import Banner from "../Banner/Banner";
import WeDo from "../WeDo/WeDo";
import Actions from "../Actions/Actions";
import Calendar from "../Calendar/Calendar";
import Articles from "../Articles/Articles";
import Partners from "../Partners/Partners";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";
import { HeaderContext } from "../HeaderContext";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

function Main() {
  const [position, setPosition] = useState(false);
  const { state } = useLocation();
  const globalHeader = useContext(HeaderContext);

  globalHeader.setHeaderState(false);

  useEffect(() => {
    const element = document.getElementById(state);
    element && element.scrollIntoView({ behavior: "smooth" });
    setPosition(state);
  }, [position, state]);

  return (
    <motion.main>
      <Banner />
      <AboutUs />
      <WeDo />
      <Actions />
      <Articles />
      <Calendar />
      <Partners />
      <ContactUs />
      <Footer />
    </motion.main>
  );
}

export default Main;

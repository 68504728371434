import React from "react";

function CalendarCards({ day, month, title, description, hour }) {
  return (
    <div className="calendar-card">
      <div className="calendar-card-date">
        <span className="calendar-card-date--day">{day}</span>
        <span className="calendar-card-date--month">{month}</span>
      </div>
      <div className="calendar-card-info">
        <h3 className="calendar-card-info--title">{title}</h3>
        <div className="calendar-card-info--content">
          <span className="calendar-card-icon-desc"></span>
          <p>{description}</p>
        </div>
        <div className="calendar-card-info--hour">
          <span className="calendar-card-icon-hour"></span>
          <p>{hour}</p>
        </div>
      </div>
    </div>
  );
}

export default CalendarCards;

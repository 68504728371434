import React, { useState } from "react";
import Button from "../common/Button";
import { Link } from "react-router-dom";
const body = document.querySelector("body");

function Navbar({ menuClass }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    const isMobile = window.innerWidth <= 768;

    if(!isMobile) return;

    setIsOpen(!isOpen);
    !isOpen
      ? (body.style.overflowY = "hidden")
      : (body.style.overflowY = "auto");
  }

  return (
    <nav
      className={`navbar${menuClass || ""} ${isOpen ? "is-open" : ""}`}
    >
      {menuClass && (
        <>
          <div className="navbar-mobile-overlay"></div>
          <Button
            buttonClass={`btn-menu-mobile`}
            dataId={"btn-menu-mobile"}
            handleClick={handleClick}
            ariaLabel={isOpen ? "Fechar Menu" : "Abrir Menu"}
            ariaHaspopup="true"
            ariaExpanded={isOpen ? true : false}
            ariaControls="menu"
          />
        </>
      )}
      <ul className={`navbar-menu ${isOpen ? "active" : ""}`} role="menu">
        <li className="menu-item">
          <Link className={"link"} to={'/#about-us'} state={"about-us"} onClick={handleClick}>Quem somos</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/#we-do"} state={"we-do"} onClick={handleClick}>O que fazemos</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/#actions"} state={"actions"} onClick={handleClick}>Ações</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/showcase-search"} state={"showcase"} onClick={handleClick}>Vitrine</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/#articles"} state={"articles"} onClick={handleClick}>Artigos</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/#calendar"} state={"calendar"} onClick={handleClick}>Agenda</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/#partners"} state={"partners"} onClick={handleClick}>Parceiros</Link>
        </li>
        <li className="menu-item">
          <Link className={"link"} to={"/#contact-us"} state={"contact-us"} onClick={handleClick}>Contato</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;

import React from 'react'
import Link from '../common/Link'

function extractFirstLine(html) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  const firstParagraph = tempElement.querySelector('em');
  const content = firstParagraph.textContent;
  return content;
}

// Esempio di utilizzo della funzione


function Article({link, title, content}) {
  const author = extractFirstLine(content);
  return (
    <div className="article-item">
      <Link href={link} content={title} />
      <p>Por: <em>{author}</em></p>      
    </div>
  )
}

export default Article
import React, { useState, useEffect } from "react";
import CalendarCards from "./CalendarCards";
import ContentEmpty from "../common/ContentEmpty";
import Loader from "../common/Loader";
import { loadAgenda } from "../../services/googleAgendaSerivce";
import moment from "moment/moment";
import "moment/locale/pt-br";

function Calendar() {
  let [dataEvent, setDataEvent] = useState(null);
  let [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        //Get Events
        const eventsCalendar = await loadAgenda();

        if ([200, 304].includes(eventsCalendar.status)) {
          setLoading(false);
          // Events
          const events = eventsCalendar.result.items;

          // Array events EMPTY!!
          if (!events.length) return;

          const dataEvents = events.map((event) => {
            return {
              day: moment(event.start.dateTime).format("D"),
              month: moment(event.start.dateTime).format("MMM"),
              title: event.summary,
              description: event.description,
              hour:
                moment(event.start.dateTime).format("HH[H]") +
                " - " +
                moment(event.end.dateTime).format("HH[H]"),
            };
          });

          setDataEvent(dataEvents);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);
  return (
    <section id="calendar" className="calendar">
      {isLoading && <Loader />}

      {!isLoading && !dataEvent && (
        <ContentEmpty
          title={"Ainda estamos sem eventos futuros agendados :("}
          content={"Em breve retomaremos a programação habitual"}
        />
      )}

      {!isLoading && dataEvent && (
        <div className="calendar-content container">
          <div className="calendar-content-cards">
            {dataEvent &&
              dataEvent.map((data) => {
                return <CalendarCards {...data} key={data.title} />;
              })}
          </div>
        </div>
      )}

      <h2 className="calendar-title">
        A<br />
        ge
        <br />
        nda
      </h2>
    </section>
  );
}

export default Calendar;

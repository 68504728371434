import React from 'react'

function Footer() {
    const date = new Date();
  return (
    <footer>
        <p className='footer-message'>
        © {date.getFullYear()} desenvolvido por tiziu
        </p>
    </footer>
  )
}

export default Footer
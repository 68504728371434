import './App.scss';
import Header from './components/Header/Header';
import AppRoutes from './assets/js/app.routes';
import { HeaderContext } from './components/HeaderContext';
import { useContext } from 'react';


function App() {
  const globalHeader = useContext(HeaderContext);
  return (
      <div className={`App ${globalHeader.headerState ? 'bg-secondary' : 'bg-primary'}`}>
        <Header />
        <AppRoutes/>       
      </div>
  );
}

export default App;

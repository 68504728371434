import React from "react";
import Slider from "react-slick";
import { getImageAndAlt } from "../../../assets/js/imageHelper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ModalCard({ isOpen, showcases, sliderRef, closeModal, imageClass }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className={`modal-showcase ${isOpen}`}
      id="modal-showcase"
      tabIndex="-1"
      role="dialog"
      aria-hidden={!isOpen ? true : false}
    >
      <div className="modal-showcase-container">
        <button
          className="modal-showcase-btn"
          aria-label="Fechar Modal"
          onClick={closeModal}
        ></button>
        <Slider {...settings} ref={sliderRef}>
          {showcases &&
            showcases.map((showcase) => {
              const dataImage = getImageAndAlt(showcase.imagens);
              const areas = showcase.areas && showcase.areas.map(area => area.nome).join(', ');

              return (
                <div key={showcase.nome}>
                  <div id={showcase.id} className="modal-showcase-slider">
                    <div className="modal-showcase-content">
                      <h3 className="modal-showcase-content--title">
                        {showcase.nome}
                      </h3>
                      <p className="modal-showcase-content--description">
                        {showcase.descricao}
                      </p>
                    </div>

                    <img
                      className={`modal-showcase-image ${imageClass || ''}`}
                      src={dataImage.src}
                      alt={dataImage.alt}
                    />

                    <div className="modal-showcase-tags--info">
                      <div className="showcase-tag">
                        <span className="showcase-tag--title">Cidade - UF</span>
                        <span className="showcase-tag--content">
                          {showcase.cidade} - {showcase.estado}
                        </span>
                      </div>
                      <div className="showcase-tag">
                        <span className="showcase-tag--title">Endereço</span>
                        <span className="showcase-tag--content">
                          {showcase.endereco}
                        </span>
                      </div>
                      <div className="showcase-tag">
                        <span className="showcase-tag--title">Contato</span>
                        <span className="showcase-tag--content">
                          <a href={`tel:+${showcase.telefone}`}>{showcase.telefone}</a>
                        </span>
                      </div>
                    </div>
                    <div className="modal-showcase-tags--area">
                      <div className="showcase-tag">
                        <span className="showcase-tag--title">Área de Atuação</span>
                        <span className="showcase-tag--content">
                          {areas}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
}

export default ModalCard;

import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import Link from "../common/Link";

import "react-toastify/dist/ReactToastify.css";

function regexEmail() {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

function regexName() {
  return /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
}

function Form() {
  let [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm();

  const onSubmit = async (dataEmail) => {
    const url = `${process.env.REACT_APP_URL_BACKEND}/enviar_email`;
    const data =  {
      nome: dataEmail.name,
      email: dataEmail.email,
      mensagem: dataEmail.message,
      token: process.env.REACT_APP_TOKEN
    };

    const response = await axios.post(url, data);

    console.log(response)

    if ([204, 304].includes(response.status)) {
      toast.success("Sua mensagem foi enviada com sucesso!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
    } else {
      toast.error(
        `Opss! Tivemos um problema no envio do email. 
          Por favor, tente novamente mais tarde.`,
        {
          position: "bottom-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsLoading(false);
    }

    reset();
  };

  React.useEffect(() => {
    formState.isSubmitSuccessful && reset();
  }, [formState, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-control">
        <label htmlFor="name">Nome</label>
        <input
          {...register("name", {
            required: "Este campo deve ser preenchido",
            minLength: {
              value: 3,
              message: "O nome deve conter no mínimo 3 letras",
            },
            pattern: {
              value: regexName(),
              message: "O nome deve conter somente letras",
            },
          })}
          id="name"
          placeholder="Digite seu nome"
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <p className="form-error">{message}</p>}
        />
      </div>

      <div className="form-control">
        <label htmlFor="email">Email</label>
        <input
          {...register("email", {
            required: "Este campo deve ser preenchido",
            pattern: {
              value: regexEmail(),
              message: "Formato de email inválido",
            },
          })}
          id="email"
          type="email"
          placeholder="seu@email.com.br"
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => <p className="form-error">{message}</p>}
        />
      </div>

      <div className="form-control">
        <label htmlFor="message">Mensagem</label>
        <textarea
          {...register("message", {
            required: "Este campo deve ser preenchido",
            minLength: {
              value: 20,
              message: "A mensagem deve conter no mínimo 20 caracteres",
            },
          })}
          placeholder="Digite sua mensagem..."
          id="message"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name="message"
          render={({ message }) => <p className="form-error">{message}</p>}
        />
      </div>

      <div className="form-control-checkbox">
        <input
          {...register("privacyPolicy", {
            required: "Este campo deve ser preenchido",
          })}
          type="checkbox"
          id="privacyPolicy"
          className="checkbox"
        />
        <label htmlFor="privacyPolicy">
          Estou de acordo com a{" "}
          <Link href={"#"} content={"política de privacidade"} /> do site e com
          o uso dos meus dados conforme descrito
        </label>
        <ErrorMessage
          errors={errors}
          name="privacyPolicy"
          render={({ message }) => <p className="form-error">{message}</p>}
        />
      </div>

      <input
        type="submit"
        className="btn btn-secondary--dark btn-form"
        value="enviar"
        disabled={isLoading}
      />
      <ToastContainer />
    </form>
  );
}

export default Form;
